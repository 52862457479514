
.cart_section
{
	width: 100%;
	padding-top: 93px;
	padding-bottom: 111px;
}
.cart_title
{
	font-size: 30px;
	font-weight: 500;
}
.cart_items
{
	margin-top: 67px;
}
.cart_list
{
	border: solid 1px #e8e8e8;
	box-shadow: 0px 1px 5px rgba(0,0,0,0.1);
}
.cart_item
{
	width: 100%;
	padding: 15px;
	padding-right: 46px;
}
.cart_item_image
{
	width: 133px;
	height: 133px;
	float: left;
}
.cart_item_image img
{
	max-width: 100%;
}
.cart_item_info
{
	width: calc(100% - 133px);
	float: left;
	padding-top: 18px;
}
.cart_item_name
{
	margin-left: 7.53%;
}
.cart_item_title
{
	font-size: 14px;
	font-weight: 400;
	color: rgba(0,0,0,0.5);
}
.cart_item_text
{
	font-size: 18px;
	margin-top: 35px;
}
.cart_item_text span
{
	display: inline-block;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	margin-right: 11px;
	-webkit-transform: translateY(4px);
	-moz-transform: translateY(4px);
	-ms-transform: translateY(4px);
	-o-transform: translateY(4px);
	transform: translateY(4px);
}
.cart_item_price
{
	text-align: right;
}
.cart_item_total
{
	text-align: right;
}
.order_total
{
	width: 100%;
	height: 60px;
	margin-top: 30px;
	border: solid 1px #e8e8e8;
	box-shadow: 0px 1px 5px rgba(0,0,0,0.1);
	padding-right: 46px;
	padding-left: 15px;
}
.order_total_title
{
	display: inline-block;
	font-size: 14px;
	color: rgba(0,0,0,0.5);
	line-height: 60px;
}
.order_total_amount
{
	display: inline-block;
	font-size: 18px;
	font-weight: 500;
	margin-left: 26px;
	line-height: 60px;
}
.cart_buttons
{
	margin-top: 60px;
	text-align: right;
}
.cart_button_clear
{
	display: inline-block;
    border: none;
    font-size: 18px;
    font-weight: 400;
    line-height: 48px;
    color: rgba(0,0,0,0.5);
    background: #FFFFFF;
    border: solid 1px #b2b2b2;
    padding-left: 35px;
    padding-right: 35px;
    outline: none;
    cursor: pointer;
    margin-right: 26px;
}
.cart_button_clear:hover
{
	border-color: #0e8ce4;
	color: #0e8ce4;
}
.cart_button_checkout
{
	display: inline-block;
    border: none;
    font-size: 18px;
    font-weight: 400;
    line-height: 48px;
    color: #FFFFFF;
    padding-left: 35px;
    padding-right: 35px;
    outline: none;
    cursor: pointer;
    vertical-align: top;
}

/* Quantity */
.product_quantity
{
	width: 182px;
	height: 50px;
	border: solid 1px #e5e5e5;
	border-radius: 5px;
	overflow: hidden;
	padding-left: 25px;
	float: left;
	margin-right: 30px;
}
.product_quantity span
{
	display: block;
	height: 50px;
	font-size: 16px;
	font-weight: 300;
	color: rgba(0,0,0,0.5);
	line-height: 50px;
	float: left;
}
.product_quantity input
{
	display: block;
	width: 30px;
	height: 50px;
	border: none;
	outline: none;
	font-size: 16px;
	font-weight: 300;
	color: rgba(0,0,0,0.5);
	text-align: left;
	padding-left: 9px;
	line-height: 50px;
	float: left;
}
.quantity_buttons
{
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	width: 29px;
	border-left: solid 1px #e5e5e5;
}
.quantity_inc, 
.quantity_dec
{
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 50%;
	cursor: pointer;
}
.quantity_control i
{
	font-size: 11px;
	color: rgba(0,0,0,0.3);
	pointer-events: none;
}
.quantity_control:active
{
	border: solid 1px rgba(14, 140, 228, 0.2);
}
.quantity_inc
{
	padding-bottom: 2px;
	justify-content: flex-end;
	border-top-right-radius: 5px;
}
.quantity_dec
{
	padding-top: 2px;
	justify-content: flex-start;
	border-bottom-right-radius: 5px;
}

/* Cart 2 form */
html,body,.wrapper{
    background: #f7f7f7;
}
.steps {
    margin-top: -41px;
    display: inline-block;
    float: right;
    font-size: 16px
}
.step {
    float: left;
    background: white;
    padding: 7px 13px;
    border-radius: 1px;
    text-align: center;
    width: 100px;
    position: relative
}
.step_line {
    margin: 0;
    width: 0;
    height: 0;
    border-left: 16px solid #fff;
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
    z-index: 1008;
    position: absolute;
    left: 99px;
    top: 1px
}
.step_line.backline {
    border-left: 20px solid #f7f7f7;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    z-index: 1006;
    position: absolute;
    left: 99px;
    top: -3px
}
.step_complete {
    background: #357ebd
}
.step_complete a.check-bc, .step_complete a.check-bc:hover,.afix-1,.afix-1:hover{
    color: #eee;
}
.step_line.step_complete {
    background: 0;
    border-left: 16px solid #357ebd
}
.step_thankyou {
    float: left;
    background: white;
    padding: 7px 13px;
    border-radius: 1px;
    text-align: center;
    width: 100px;
}
.step.check_step {
    margin-left: 5px;
}
.ch_pp {
    text-decoration: underline;
}
.ch_pp.sip {
    margin-left: 10px;
}
.check-bc,
.check-bc:hover {
    color: #222;
}
.SuccessField {
    border-color: #458845 !important;
    -webkit-box-shadow: 0 0 7px #9acc9a !important;
    -moz-box-shadow: 0 0 7px #9acc9a !important;
    box-shadow: 0 0 7px #9acc9a !important;
    /* background: #f9f9f9 url(../images/valid.png) no-repeat 98% center !important */
}

.btn-xs{
    line-height: 28px;
}

/*login form*/
.login-container{
    margin-top:30px ;
}
.login-container input[type=submit] {
  width: 100%;
  display: block;
  margin-bottom: 10px;
  position: relative;
}

.login-container input[type=text], input[type=password] {
  height: 44px;
  font-size: 16px;
  width: 100%;
  margin-bottom: 10px;
  -webkit-appearance: none;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-top: 1px solid #c0c0c0;
  /* border-radius: 2px; */
  padding: 0 8px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.login-container input[type=text]:hover, input[type=password]:hover {
  border: 1px solid #b9b9b9;
  border-top: 1px solid #a0a0a0;
  -moz-box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
  -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
  box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
}

.login-container-submit {
  /* border: 1px solid #3079ed; */
  border: 0px;
  color: #fff;
  text-shadow: 0 1px rgba(0,0,0,0.1); 
  background-color: #357ebd;/*#4d90fe;*/
  padding: 17px 0px;
  font-family: roboto;
  font-size: 14px;
  /* background-image: -webkit-gradient(linear, 0 0, 0 100%,   from(#4d90fe), to(#4787ed)); */
}

.login-container-submit:hover {
  /* border: 1px solid #2f5bb7; */
  border: 0px;
  text-shadow: 0 1px rgba(0,0,0,0.3);
  background-color: #357ae8;
  /* background-image: -webkit-gradient(linear, 0 0, 0 100%,   from(#4d90fe), to(#357ae8)); */
}

.login-help{
  font-size: 12px;
}

/* .asterix{
    background:#f9f9f9 url(../images/red_asterisk.png) no-repeat 98% center !important;
} */

/* images*/
ol, ul {
  list-style: none;
}
.hand {
  cursor: pointer;
  cursor: pointer;
}
.cards{
    padding-left:0;
}
.cards li {
  -webkit-transition: all .2s;
  -moz-transition: all .2s;
  -ms-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
  background-image: url('//c2.staticflickr.com/4/3713/20116660060_f1e51a5248_m.jpg');
  background-position: 0 0;
  float: left;
  height: 32px;
  margin-right: 8px;
  text-indent: -9999px;
  width: 51px;
}
.cards .mastercard {
  background-position: -51px 0;
}
.cards li {
  -webkit-transition: all .2s;
  -moz-transition: all .2s;
  -ms-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
  background-image: url('//c2.staticflickr.com/4/3713/20116660060_f1e51a5248_m.jpg');
  background-position: 0 0;
  float: left;
  height: 32px;
  margin-right: 8px;
  text-indent: -9999px;
  width: 51px;
}
.cards .amex {
  background-position: -102px 0;
}
.cards li {
  -webkit-transition: all .2s;
  -moz-transition: all .2s;
  -ms-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
  background-image: url('//c2.staticflickr.com/4/3713/20116660060_f1e51a5248_m.jpg');
  background-position: 0 0;
  float: left;
  height: 32px;
  margin-right: 8px;
  text-indent: -9999px;
  width: 51px;
}
.cards li:last-child {
  margin-right: 0;
}
/* images end */



/*
 * BOOTSTRAP
 */
.container{
    border: none;
}
.panel-footer{
    background:#fff;
}
.btn{
    border-radius: 1px;
}
.btn-sm, .btn-group-sm > .btn{
    border-radius: 1px;
}
.input-sm, .form-horizontal .form-group-sm .form-control{
    border-radius: 1px;
}

.panel-info {
    border-color: #999;
}

.panel-heading {
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;
}
.panel {
    border-radius: 1px;
}
.panel-info > .panel-heading {
    color: #eee;
    border-color: #999;
}
.panel-info > .panel-heading {
    background-image: linear-gradient(to bottom, #555 0px, #888 100%);
}

hr {
    border-color: #999 -moz-use-text-color -moz-use-text-color;
}

.panel-footer {
    border-bottom-left-radius: 1px;
    border-bottom-right-radius: 1px;
    border-top: 1px solid #999;
}

.btn-link {
    color: #888;
}

hr{
    margin-bottom: 10px;
    margin-top: 10px;
}

/** MEDIA QUERIES **/
@media only screen and (max-width: 989px){
    .span1{
        margin-bottom: 15px;
        clear:both;
    }
}

@media only screen and (max-width: 764px){
    .inverse-1{
        float:right;
    }
}

@media only screen and (max-width: 586px){
    .cart-titles{
        display:none;
    }
    .panel {
        margin-bottom: 1px;
    }
}

.form-control {
    border-radius: 1px;
}

@media only screen and (max-width: 486px){
    .col-xss-12{
        width:100%;
    }
    .cart-img-show{
        display: none;
    }
    .btn-submit-fix{
        width:100%;
    }
    
}
/*
@media only screen and (max-width: 777px){
    .container{
        overflow-x: hidden;
    }
}*/