.shop
{
	background: #FFFFFF;
	padding-top: 80px;
	padding-bottom: 139px;
}
.shop_content
{
	width: 100%;
}
.shop_bar
{
	border-bottom: solid 1px #dadada;
	padding-bottom: 14px;
	z-index: 1;
}
.shop_product_count
{
	font-size: 14px;
	font-weight: 500;
	float: left;
}
.shop_product_count span
{
	color: #0e8ce4;
}
.shop_sorting
{
	float: right;
}
.shop_sorting span
{
	display: inline-block;
	font-weight: 500;
}
.shop_sorting > ul
{
	display: inline-block;
	position: relative;
	margin-left: 6px;
}
.shop_sorting ul li
{
	color: rgba(0,0,0,0.5);
	cursor: pointer;
}
.shop_sorting ul li:hover
{
	color: rgba(0,0,0,0.8);
}
.shop_sorting ul li i
{
	display: inline-block;
	font-size: 10px;
	color: rgba(0,0,0,0.5);
	margin-left: 4px;
	vertical-align: middle;
	-webkit-transform: translateY(-1px);
	-moz-transform: translateY(-1px);
	-ms-transform: translateY(-1px);
	-o-transform: translateY(-1px);
	transform: translateY(-1px);
}
.shop_sorting ul li ul
{
	display: block;
	position: absolute;
	top: calc(100% + 15px);
	right: 0;
	text-align: right;
	background: #FFFFFF;
	width: auto;
	padding-top: 15px;
	visibility: hidden;
	opacity: 0;
	box-shadow: 0px 10px 25px rgba(0,0,0,0.1);
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.shop_sorting ul li:hover ul
{
	top: 100%;
	visibility: visible;
	opacity: 1;
}
.shop_sorting ul li ul li
{
	white-space: nowrap;
	padding-right: 13px;
	padding-left: 20px;
	margin-bottom: 5px;
	border-bottom: solid 1px #f2f2f2;
	padding-top: 5px;
	padding-bottom: 9px;
}
.shop_sorting ul li ul li:last-child
{
	border-bottom: none;
}

/*********************************
5.1 Shop Products
*********************************/

.product_grid
{
	-webkit-transform: translateX(-20px);
	-moz-transform: translateX(-20px);
	-ms-transform: translateX(-20px);
	-o-transform: translateX(-20px);
	transform: translateX(-20px);
	width: calc(100% + 40px);
}
.product_grid_border
{
	display: block;
	position: absolute;
	top: 0px;
	right: 0px;
	width: 3px;
	height: 100%;
	background: #FFFFFF;
	z-index: 1;
}
.product_item
{
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 20%;
	background: #FFFFFF;
	cursor: pointer;
	padding-top: 40px;
	padding-bottom: 24px;
	text-align: center;
}
.product_border
{
	display: block;
	position: absolute;
	top: 52px;
	right: 1px;
	width: 1px;
	height: calc(100% - 71px);
	background: #e5e5e5;
}
.product_image
{
	width: 100%;
	height: 115px;
}
.product_image img
{
	display: block;
	position: relative;
	max-width: 100%;
}
.product_content
{
	width: 100%;
}
.product_price
{
	font-size: 16px;
	font-weight: 500;
	margin-top: 25px;
}
.product_item.discount
{
	color: #df3b3b;
}
.product_price span
{
	position: relative;
	font-size: 12px;
	font-weight: 400;
	color: rgba(0,0,0,0.6);
	margin-left: 10px;
}
.product_price span::after
{
	display: block;
	position: absolute;
	top: 6px;
	left: -2px;
	width: calc(100% + 4px);
	height: 1px;
	background: #8d8d8d;
	content: '';
}
.product_name
{
	margin-top: 4px;
	overflow: hidden;
}
.product_name div
{
	width: 100%;
	
}
.product_name div a
{
	font-size: 14px;
	font-weight: 400;
	color: #000000;
	white-space: nowrap;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.product_name div a:hover
{
	color: #0e8ce4;
}
.product_fav
{
	position: absolute;
	top: 33px;
	right: 12px;
	width: 36px;
	height: 36px;
	background: #FFFFFF;
	box-shadow: 0px 1px 5px rgba(0,0,0,0.1);
	border-radius: 50%;
	visibility: hidden;
	opacity: 0;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.product_fav:hover
{
	box-shadow: 0px 1px 5px rgba(0,0,0,0.3);
}
.product_fav i
{
	display: block;
	position: absolute;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	transform: translateX(-50%);
	color: #cccccc;
	line-height: 36px;
	pointer-events: none;
	z-index: 0;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.product_fav.active i
{
	color: red;
}
.product_item:hover .product_fav
{
	visibility: visible;
	opacity: 1;
}
.product_marks
{
	display: block;
	position: absolute;
	top: 33px;
	left: 24px;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.product_mark
{
	display: inline-block;
	width: 36px;
	height: 36px;
	border-radius: 50%;
	color: #FFFFFF;
	text-align: center;
	line-height: 36px;
	font-size: 12px;
}
.product_new
{
	display: none;
	background: #0e8ce4;
	visibility: hidden;
	opacity: 0;
}
.product_discount
{
	display: none;
	background: #df3b3b;
	visibility: hidden;
	opacity: 0;
}
.product_item.is_new .product_new,
.product_item.discount .product_discount
{
	display: inline-block;
	visibility: visible;
	opacity: 1;	
}

/*********************************
5.2 Shop Page Navigation
*********************************/

.shop_page_nav
{
	width: 100%;
	height: 50px;
	margin-top: 80px;
}
.page_prev, 
.page_next
{
	width: 50px;
	height: 100%;
	border: solid 1px #e5e5e5;
	border-radius: 5px;
	cursor: pointer;
}
.page_prev i, 
.page_next i
{
	font-size: 12px;
	color: #e5e5e5;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.page_prev:hover i, 
.page_next:hover i
{
	color: #636363;
}
.page_nav
{
	border: solid 1px #e5e5e5;
	border-radius: 5px;
	margin-left: 15px;
	margin-right: 15px;
}
.page_nav li
{
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 50px;
	height: 50px;
	border-right: solid 1px #e5e5e5;
	cursor: pointer;
}
.page_nav li a
{
	font-weight: 500;
	color: rgba(0,0,0,0.7);
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.page_nav li:hover a
{
	color: #0e8ce4;
}
.page_nav li:last-child
{
	border-right: none;
}

/*********************************
6. Shop Sidebar
*********************************/

.shop_sidebar
{
	width: 100%;
	-webkit-transform: translateX(-37px);
	-moz-transform: translateX(-37px);
	-ms-transform: translateX(-37px);
	-o-transform: translateX(-37px);
	transform: translateX(-37px);
}
.sidebar_title
{
	font-size: 18px;
	font-weight: 500;
}
.sidebar_categories
{
	margin-top: 37px;
}
.sidebar_categories li
{
	margin-bottom: 7px;
}
.sidebar_categories li a
{
	color: rgba(0,0,0,0.5);
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.sidebar_categories li a:hover
{
	color: #0e8ce4;
}
.filter_by_section
{
	margin-top: 45px;
}
.filter_price
{
	margin-top: 28px;
}
.slider_range
{
	max-width: 195px;
}
.filter_price p
{
	display: inline-block;
	font-size: 12px;
	font-weight: 500 !important;
	color: rgba(0,0,0,0.5);
	margin-bottom: 0px;
}
.sidebar_subtitle
{
	font-size: 14px;
	font-weight: 500;
	margin-top: 25px;
}
.filter_price p input
{
	font-size: 12px;
	font-weight: 500 !important;
	color: rgba(0,0,0,0.5)
}
.amount
{
	margin-top: 18px;
}
.color_subtitle
{
	margin-top: 27px;
}
.colors_list
{
	margin-top: 14px;
}
.color
{
	display: inline-block;
	margin-right: 6px;
}
.color a
{
	display: block;
	width: 20px;
	height: 20px;
	border-radius: 50%;
}
.brands_subtitle
{
	margin-top: 27px;
}
.brands_list
{
	margin-top: 12px;
}
.brand
{
	margin-bottom: 7px;
}
.brand a
{
	color: rgba(0,0,0,0.5);
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.brand a:hover
{
	color: #0e8ce4;
}