.single_product
{
	padding-top: 110px;
	padding-bottom: 140px;
}


.image_list li
{
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 165px;
	border: solid 1px #e8e8e8;
	box-shadow: 0px 1px 5px rgba(0,0,0,0.1);
	margin-bottom: 15px;
	cursor: pointer;
	padding: 15px;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
	overflow: hidden;
}
.image_list li:last-child
{
	margin-bottom: 0;
}
.image_list li:hover
{
	box-shadow: 0px 1px 5px rgba(0,0,0,0.3);
}
.image_list li img
{
	max-width: 100%;
}
.image_selected
{
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: calc(100% + 15px);
	height: 525px;
	-webkit-transform: translateX(-15px);
	-moz-transform: translateX(-15px);
	-ms-transform: translateX(-15px);
	-o-transform: translateX(-15px);
	transform: translateX(-15px);
	border: solid 1px #e8e8e8;
	box-shadow: 0px 1px 5px rgba(0,0,0,0.1);
	overflow: hidden;
	padding: 15px;
}
.image_selected img
{
	max-width: 100%;
}
.product_description
{
	padding-left: 50px;
}
.product_category
{
	font-size: 12px;
	color: rgba(0,0,0,0.5);
}
.product_name
{
	font-size: 30px;
	font-weight: 500;
	margin-top: 11px;
}
.product_rating
{
	margin-top: 7px;
}
.product_rating i
{
	margin-right: 4px;
}
.product_rating i::before
{
	font-size: 13px;
}
.product_text
{
	margin-top: 27px;
}
.product_text p:last-child
{
	margin-bottom: 0px;
}
.order_info
{
	margin-top: 53px;
}
.product_quantity
{
	width: 182px;
	height: 50px;
	border: solid 1px #e5e5e5;
	border-radius: 5px;
	overflow: hidden;
	padding-left: 25px;
	float: left;
	margin-right: 30px;
}
.product_quantity span
{
	display: block;
	height: 50px;
	font-size: 16px;
	font-weight: 300;
	color: rgba(0,0,0,0.5);
	line-height: 50px;
	float: left;
}
.product_quantity input
{
	display: block;
	width: 30px;
	height: 50px;
	border: none;
	outline: none;
	font-size: 16px;
	font-weight: 300;
	color: rgba(0,0,0,0.5);
	text-align: left;
	padding-left: 9px;
	line-height: 50px;
	float: left;
}
.quantity_buttons
{
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	width: 29px;
	border-left: solid 1px #e5e5e5;
}
.quantity_inc, 
.quantity_dec
{
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 50%;
	cursor: pointer;
}
.quantity_control i
{
	font-size: 11px;
	color: rgba(0,0,0,0.3);
	pointer-events: none;
}
.quantity_control:active
{
	border: solid 1px rgba(14, 140, 228, 0.2);
}
.quantity_inc
{
	padding-bottom: 2px;
	justify-content: flex-end;
	border-top-right-radius: 5px;
}
.quantity_dec
{
	padding-top: 2px;
	justify-content: flex-start;
	border-bottom-right-radius: 5px;
}
.product_color
{
	width: 182px;
	height: 50px;
	border: solid 1px #e5e5e5;
	border-radius: 5px;
	float: left;
	cursor: pointer;
}
.product_color span
{
	display: block;
	height: 50px;
	font-size: 16px;
	font-weight: 300;
	color: rgba(0,0,0,0.5);
	line-height: 50px;
	float: left;
}
.color_dropdown_button
{
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	right: 0;
	width: 50px;
	height: 100%;
}
.color_dropdown_button i
{
	font-size: 11px;
	color: rgba(0,0,0,0.3);
	pointer-events: none;
}
.color_mark_container
{
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 20px;
	height: 48px;
	float: right;
}
.color_mark
{
	width: 20px;
	height: 20px;
	background: #999999;
	border-radius: 50%;
}
.product_color > li
{
	position: relative;
	width: 100%;
	height: 100%;
	padding-left: 25px;
	padding-right: 49px;
}
.product_color li ul
{
	position: absolute;
	top: calc(100% + 15px);
	right: 0;
	padding-top: 15px;
	padding-bottom: 15px;
	width: auto;
	visibility: hidden;
	opacity: 0;
	box-shadow: 0px 5px 20px rgba(0,0,0,0.1);
	background: #FFFFFF;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.product_color > li:hover ul
{
	top: 100%;
	visibility: visible;
	opacity: 1;
}
.product_color li ul li
{
	padding-right: 49px;
	padding-left: 49px;
	padding-top: 10px;
	padding-bottom: 10px;
}
.product_color li ul li div
{
	margin-left: auto;
}
.product_price
{
	display: inline-block;
	font-size: 24px;
	font-weight: 500;
	margin-top: 75px;
	clear: left;
}
.button_container
{
	margin-top: 43px;
}
.cart_button
{
	display: inline-block;
	border: none;
	font-size: 18px;
	font-weight: 400;
	line-height: 48px;
	color: #FFFFFF;
	padding-left: 35px;
	padding-right: 35px;
	outline: none;
	cursor: pointer;
}
.cart_button:focus
{
	outline: none;
}
.product_fav
{
	display: inline-block;
	width: 36px;
	height: 36px;
	background: #FFFFFF;
	box-shadow: 0px 1px 5px rgba(0,0,0,0.1);
	border-radius: 50%;
	text-align: center;
	cursor: pointer;
	margin-left: 36px;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.product_fav:hover
{
	box-shadow: 0px 1px 5px rgba(0,0,0,0.3);
}
.product_fav i
{
	line-height: 36px;
	color: #cccccc;
}
.product_fav.active i
{
	color: #df3b3b;
}